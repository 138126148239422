<template>
    <div class="video-wrapper" :style="cssVars">
        <!-- Slot for the VideoPlayer -->
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'VideoWrapper',
    props: {
        /**
         ******************** Required *******************
         */

        maxWidth: {
            type: [String, Number],
            required: true,
        },
    },
    computed: {
        /**
         * Variables we want to pass into the CSS
         */
        cssVars() {
            return '--video-max-width: '  + this.maxWidth;
        },
    },
}
</script>
